import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Title } from '@/components/Title';
import { DatesSlider } from '@/components/DatesSlider';
import { TimeIntervalPagination } from '@/components/TimeIntervalPagination';
import { FamousDatesProvider } from '@/context/FamousDatesProvider';
import { AppContainer } from '@/components/AppContainer';
import { TimeInterval } from '@/components/TimeInterval';
import { CircleNav } from '@/components/CircleNav';
import { Main } from '@/components/Main';
import { DotPagination } from '@/components/TimeIntervalPagination/DotPagination';
export var App = function () {
    return (_jsx(FamousDatesProvider, { children: _jsxs(AppContainer, { children: [_jsxs(Main, { children: [_jsx(Title, {}), _jsx(CircleNav, {}), _jsx(TimeInterval, {}), _jsx(TimeIntervalPagination, {}), _jsx(DotPagination, {})] }), _jsx(DatesSlider, {})] }) }));
};
