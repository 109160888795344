import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { FamousDatesContext } from '@/context/FamousDatesContext';
import { famousDatesData } from '@/data/famous-dates';
export var FamousDatesProvider = function (_a) {
    var children = _a.children;
    var _b = useState(1), activeStage = _b[0], setActiveStage = _b[1];
    var _c = useState(1), prevActiveStage = _c[0], setPrevActiveStage = _c[1];
    var FamousDatesContextValue = {
        famousDates: famousDatesData,
        activeStage: activeStage,
        setActiveStage: setActiveStage,
        prevActiveStage: prevActiveStage,
        setPrevActiveStage: setPrevActiveStage,
    };
    return (_jsx(FamousDatesContext.Provider, { value: FamousDatesContextValue, children: children }));
};
