var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var ITEM_WIDTH = '56px';
var ITEM_HEIGHT = '56px';
var ACTIVE_BACKGROUND_COLOR = '#f4f5f9';
var FONT_SIZE = '20px';
var TRANSITION_DURATION = '.2s';
export var StyledNavItem = styled.div.attrs(function (props) { return ({
    $position: props.$position,
    $angleRotate: props.$angleRotate,
    $isActiveStage: props.$isActiveStage,
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  font-size: ", ";\n  line-height: 30px;\n  font-weight: 400;\n  color: #42567a;\n\n  border: 1px solid #929aa9;\n  border-radius: 50%;\n\n  position: absolute;\n  top: ", ";\n  left: ", ";\n  width: ", ";\n  height: ", ";\n  z-index: 1;\n\n  background-color: ", ";\n\n  transition:\n    width ", " linear,\n    height ", " linear,\n    background-color ", " linear,\n    font-size 0.1s linear;\n\n  transform: ", ";\n\n  &:after {\n    content: ", ";\n    font-family: 'PT Sans Bold';\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 30px;\n    position: absolute;\n    left: 75px;\n  }\n\n  &:hover {\n    cursor: ", ";\n    width: ", ";\n    height: ", ";\n    background-color: ", ";\n    font-size: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  font-size: ", ";\n  line-height: 30px;\n  font-weight: 400;\n  color: #42567a;\n\n  border: 1px solid #929aa9;\n  border-radius: 50%;\n\n  position: absolute;\n  top: ", ";\n  left: ", ";\n  width: ", ";\n  height: ", ";\n  z-index: 1;\n\n  background-color: ", ";\n\n  transition:\n    width ", " linear,\n    height ", " linear,\n    background-color ", " linear,\n    font-size 0.1s linear;\n\n  transform: ", ";\n\n  &:after {\n    content: ", ";\n    font-family: 'PT Sans Bold';\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 30px;\n    position: absolute;\n    left: 75px;\n  }\n\n  &:hover {\n    cursor: ", ";\n    width: ", ";\n    height: ", ";\n    background-color: ", ";\n    font-size: ", ";\n  }\n"])), function (_a) {
    var $isActiveStage = _a.$isActiveStage;
    return "".concat($isActiveStage ? FONT_SIZE : '0');
}, function (_a) {
    var $position = _a.$position;
    return "".concat($position.top, "%");
}, function (_a) {
    var $position = _a.$position;
    return "".concat($position.left, "%");
}, function (_a) {
    var $isActiveStage = _a.$isActiveStage;
    return "".concat($isActiveStage ? ITEM_WIDTH : '6px');
}, function (_a) {
    var $isActiveStage = _a.$isActiveStage;
    return "".concat($isActiveStage ? ITEM_HEIGHT : '6px');
}, function (_a) {
    var $isActiveStage = _a.$isActiveStage;
    return "".concat($isActiveStage ? ACTIVE_BACKGROUND_COLOR : '#42567A');
}, TRANSITION_DURATION, TRANSITION_DURATION, TRANSITION_DURATION, function (_a) {
    var $angleRotate = _a.$angleRotate;
    return "translate(-50%, -50%) rotate(".concat($angleRotate, "deg)");
}, function (_a) {
    var $isActiveStage = _a.$isActiveStage, title = _a.title;
    return "".concat($isActiveStage && title ? "'".concat(title, "'") : '');
}, function (_a) {
    var $isActiveStage = _a.$isActiveStage;
    return "".concat($isActiveStage ? 'default' : 'pointer');
}, ITEM_WIDTH, ITEM_HEIGHT, ACTIVE_BACKGROUND_COLOR, FONT_SIZE);
var templateObject_1;
