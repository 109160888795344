import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { TimeIntervalContainer } from '@/components/TimeInterval/TimeIntervalContainer';
import { FamousDatesContext } from '@/context/FamousDatesContext';
import { TimeIntervalYear } from '@/components/TimeInterval/TimeIntervalYear';
import { useAnimationYear } from '@/hooks/useAnimationYear';
export var TimeInterval = function () {
    var _a = useContext(FamousDatesContext), famousDates = _a.famousDates, activeStage = _a.activeStage, prevActiveStage = _a.prevActiveStage;
    var _b = famousDates.find(function (el) { return el.stage === activeStage; }).timeInterval, activeStartYear = _b.start, activeEndYear = _b.end;
    var _c = famousDates.find(function (el) { return el.stage === prevActiveStage; }).timeInterval, prevStartYear = _c.start, prevEndYear = _c.end;
    var start = useAnimationYear(activeStartYear, prevStartYear);
    var end = useAnimationYear(activeEndYear, prevEndYear);
    return (_jsxs(TimeIntervalContainer, { children: [_jsx(TimeIntervalYear, { children: start }), _jsx(TimeIntervalYear, { "$accent": true, children: end })] }));
};
