import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FamousDatesContext } from '@/context/FamousDatesContext';
import { Container } from '@/components/TimeIntervalPagination/DotPagination/Container';
import { DotPaginationItem } from '@/components/TimeIntervalPagination/DotPagination/DotPaginationItem';
export var DotPagination = function () {
    var _a = useContext(FamousDatesContext), famousDates = _a.famousDates, activeStage = _a.activeStage, setActiveStage = _a.setActiveStage, setPrevActiveStage = _a.setPrevActiveStage;
    var dotPaginationItems = famousDates.map(function (item) {
        return (_jsx(DotPaginationItem, { "$isActiveStage": item.stage === activeStage, onClick: function () {
                setPrevActiveStage(activeStage);
                setActiveStage(item.stage);
            } }, uuidv4()));
    });
    return _jsx(Container, { children: dotPaginationItems });
};
