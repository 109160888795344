var size = {
    mobile: '576px',
    tabletM: '768px',
    tabletL: '992px',
};
export var device = {
    mobile: "(max-width: ".concat(size.mobile, ")"),
    tabletM: "(max-width: ".concat(size.tabletM, ")"),
    tabletL: "(max-width: ".concat(size.tabletL, ")"),
};
