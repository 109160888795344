var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import prevIcon from './prevIcon.png';
import nextIcon from './nextIcon.png';
import styled from 'styled-components';
import { device } from '@/components/GlobalStyle/breakpoints';
export var ChangeStageButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid #9ba6ba;\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  color: #42567a;\n  font-weight: 400;\n  transition: opacity 0.5s;\n  background: ", ";\n\n  &:hover {\n    cursor: pointer;\n    opacity: 0.6;\n  }\n\n  &:disabled {\n    cursor: default;\n    opacity: 0.5;\n  }\n\n  @media ", " {\n    width: 25px;\n    height: 25px;\n    background-size: 6px;\n  }\n"], ["\n  border: 1px solid #9ba6ba;\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  color: #42567a;\n  font-weight: 400;\n  transition: opacity 0.5s;\n  background: ", ";\n\n  &:hover {\n    cursor: pointer;\n    opacity: 0.6;\n  }\n\n  &:disabled {\n    cursor: default;\n    opacity: 0.5;\n  }\n\n  @media ", " {\n    width: 25px;\n    height: 25px;\n    background-size: 6px;\n  }\n"])), function (_a) {
    var direction = _a.direction;
    return "url(".concat(direction === 'prev' ? prevIcon : nextIcon, ") no-repeat center");
}, device.mobile);
var templateObject_1;
