var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import PTSansRegular from './fonts/PTSans-Regular.ttf';
import PTSansBold from './fonts/PTSans-Bold.ttf';
import BebasNeueRegular from './fonts/BebasNeue-Regular.ttf';
import { createGlobalStyle } from 'styled-components';
export var FontStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @font-face {\n    font-family: 'PT Sans Regular';\n    font-weight: 400;\n    font-display: swap;\n    src: local('PT Sans Regular'), local('PTSansRegular'),\n    url(", ") format('truetype'),\n  }\n\n  @font-face {\n    font-family: 'PT Sans Bold';\n    font-weight: 700;\n    font-display: swap;\n    src: local('PT Sans Bold'), local('PTSansBold'),\n    url(", ") format('truetype'),\n  }\n  \n  @font-face {\n    font-family: 'Bebas Neue';\n    font-weight: 400;\n    font-display: swap;\n    src: local('Bebas Neue'), local('BebasNeue'),\n    url(", ") format('truetype'),\n  }\n"], ["\n  @font-face {\n    font-family: 'PT Sans Regular';\n    font-weight: 400;\n    font-display: swap;\n    src: local('PT Sans Regular'), local('PTSansRegular'),\n    url(", ") format('truetype'),\n  }\n\n  @font-face {\n    font-family: 'PT Sans Bold';\n    font-weight: 700;\n    font-display: swap;\n    src: local('PT Sans Bold'), local('PTSansBold'),\n    url(", ") format('truetype'),\n  }\n  \n  @font-face {\n    font-family: 'Bebas Neue';\n    font-weight: 400;\n    font-display: swap;\n    src: local('Bebas Neue'), local('BebasNeue'),\n    url(", ") format('truetype'),\n  }\n"])), PTSansRegular, PTSansBold, BebasNeueRegular);
var templateObject_1;
