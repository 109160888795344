import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@/components/App';
import { GlobalStyle } from '@/components/GlobalStyle';
var container = document.getElementById('root');
if (!container) {
    throw new Error('Root element not found');
}
var root = createRoot(container);
root.render(_jsxs(React.StrictMode, { children: [_jsx(GlobalStyle, {}), _jsx(App, {})] }));
