var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var DotPaginationItem = styled.div.attrs(function (props) { return ({
    $isActiveStage: props.$isActiveStage,
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 6px;\n  height: 6px;\n  border-radius: 50%;\n  background-color: #42567a;\n  opacity: ", ";\n"], ["\n  width: 6px;\n  height: 6px;\n  border-radius: 50%;\n  background-color: #42567a;\n  opacity: ", ";\n"])), function (_a) {
    var $isActiveStage = _a.$isActiveStage;
    return "".concat($isActiveStage ? '1' : '0.4');
});
var templateObject_1;
