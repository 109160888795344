var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { device } from '@/components/GlobalStyle/breakpoints';
export var TimeIntervalYear = styled.h3.attrs(function (props) { return ({
    $accent: props.$accent,
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: 'PT Sans Bold';\n  font-size: 200px;\n  font-weight: 700;\n  line-height: 160px;\n  letter-spacing: -5px;\n  color: ", ";\n  margin: 0;\n\n  @media ", " {\n    font-size: 140px;\n  }\n\n  @media ", " {\n    font-size: 110px;\n  }\n\n  @media ", " {\n    font-size: 56px;\n    line-height: 72px;\n    letter-spacing: -2px;\n  }\n"], ["\n  font-family: 'PT Sans Bold';\n  font-size: 200px;\n  font-weight: 700;\n  line-height: 160px;\n  letter-spacing: -5px;\n  color: ", ";\n  margin: 0;\n\n  @media ", " {\n    font-size: 140px;\n  }\n\n  @media ", " {\n    font-size: 110px;\n  }\n\n  @media ", " {\n    font-size: 56px;\n    line-height: 72px;\n    letter-spacing: -2px;\n  }\n"])), function (_a) {
    var $accent = _a.$accent;
    return ($accent ? '#EF5DA8' : '#5D5FEF');
}, device.tabletL, device.tabletM, device.mobile);
var templateObject_1;
