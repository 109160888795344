import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { StyledNavItem } from '@/components/CircleNav/NavItem/StyledNavItem';
import { FamousDatesContext } from '@/context/FamousDatesContext';
export var NavItem = function (_a) {
    var stage = _a.stage, position = _a.position, onClick = _a.onClick, angleRotate = _a.angleRotate, isActiveStage = _a.isActiveStage, title = _a.title;
    var _b = useContext(FamousDatesContext), activeStage = _b.activeStage, prevActiveStage = _b.prevActiveStage;
    var _c = useState(''), itemTitle = _c[0], setItemTitle = _c[1];
    useEffect(function () {
        if (isActiveStage) {
            if (activeStage === prevActiveStage) {
                setItemTitle(title);
            }
            if (activeStage !== prevActiveStage) {
                setTimeout(function () { return setItemTitle(title); }, 900);
            }
        }
    }, [isActiveStage, title, activeStage, prevActiveStage]);
    return (_jsx(StyledNavItem, { "$position": position, onClick: onClick, "$angleRotate": angleRotate, "$isActiveStage": isActiveStage, title: itemTitle, children: stage }));
};
