import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Circle } from '@/components/CircleNav/Circle';
import { FamousDatesContext } from '@/context/FamousDatesContext';
import { NavItem } from '@/components/CircleNav/NavItem';
import { calculateNavItemPosition } from '@/utils/calculateNavItemPosition';
import { calculateRotateCircle } from '@/utils/calculateRotateCircle';
import { Container } from '@/components/CircleNav/Container';
export var CircleNav = function () {
    var _a = useState(0), angleRotate = _a[0], setAngleRotate = _a[1];
    var _b = useContext(FamousDatesContext), famousDates = _b.famousDates, activeStage = _b.activeStage, setActiveStage = _b.setActiveStage, setPrevActiveStage = _b.setPrevActiveStage;
    var handleNavItemClick = function (stage) {
        setPrevActiveStage(activeStage);
        setActiveStage(stage);
    };
    var circleNavItems = famousDates.map(function (item) {
        return (_jsx(NavItem, { stage: item.stage, position: calculateNavItemPosition({
                stage: item.stage,
                amountItems: famousDates.length,
            }), angleRotate: angleRotate, isActiveStage: item.stage === activeStage, title: item.title, onClick: function () { return handleNavItemClick(item.stage); } }, uuidv4()));
    });
    useEffect(function () {
        setAngleRotate(calculateRotateCircle({
            stage: activeStage,
            amountItems: famousDates.length,
        }));
    }, [activeStage, famousDates.length]);
    return (_jsx(Circle, { "$angleRotate": angleRotate, children: _jsx(Container, { children: circleNavItems }) }));
};
