import { useEffect, useState } from 'react';
export var useAnimationYear = function (activeYear, prevYear) {
    var _a = useState(prevYear), year = _a[0], setYear = _a[1];
    useEffect(function () {
        if (activeYear > year) {
            setTimeout(function () { return setYear(year + 1); }, 30);
        }
        if (activeYear < year) {
            setTimeout(function () { return setYear(year - 1); }, 30);
        }
    }, [activeYear, year]);
    return year;
};
