var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { FontStyle } from '@/components/FontStyle';
var Styles = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  * {\n    box-sizing: border-box;\n  }\n\n  body {\n    font-family: 'PT Sans Regular', Arial, sans-serif;\n    background: #f4f5f9;\n    min-width: 320px;\n    max-width: 1440px;\n    margin: 0 auto;\n  }\n"], ["\n  * {\n    box-sizing: border-box;\n  }\n\n  body {\n    font-family: 'PT Sans Regular', Arial, sans-serif;\n    background: #f4f5f9;\n    min-width: 320px;\n    max-width: 1440px;\n    margin: 0 auto;\n  }\n"])));
export var GlobalStyle = function () {
    return (_jsxs(_Fragment, { children: [_jsx(Normalize, {}), _jsx(FontStyle, {}), _jsx(Styles, {})] }));
};
var templateObject_1;
