var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { device } from '@/components/GlobalStyle/breakpoints';
export var Circle = styled.div.attrs(function (props) { return ({
    $angleRotate: props.$angleRotate,
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 530px;\n  height: 530px;\n  border: 1px solid #d0d5e0;\n  border-radius: 50%;\n  margin: 0 auto;\n  transform: ", ";\n  transition: transform 0.9s;\n  z-index: 1;\n\n  @media ", " {\n    display: none;\n  }\n"], ["\n  width: 530px;\n  height: 530px;\n  border: 1px solid #d0d5e0;\n  border-radius: 50%;\n  margin: 0 auto;\n  transform: ", ";\n  transition: transform 0.9s;\n  z-index: 1;\n\n  @media ", " {\n    display: none;\n  }\n"])), function (_a) {
    var $angleRotate = _a.$angleRotate;
    return "rotate(-".concat($angleRotate, "deg)");
}, device.mobile);
var templateObject_1;
