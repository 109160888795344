import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FamousDatesContext } from '@/context/FamousDatesContext';
import { SlideItemTitle } from '@/components/DatesSlider/SlideItemTitle';
import { SlideItemDescription } from '@/components/DatesSlider/SlideItemDescription';
import { SlideItemContainer } from '@/components/DatesSlider/SlideItemContainer';
import { SwiperWrap } from '@/components/DatesSlider/SwiperWrap';
import { Title } from '@/components/DatesSlider/Title';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export var DatesSlider = function () {
    var _a;
    var _b = useContext(FamousDatesContext), famousDates = _b.famousDates, activeStage = _b.activeStage, prevActiveStage = _b.prevActiveStage;
    var activeFamousDates = famousDates.find(function (dates) { return dates.stage === activeStage; });
    var _c = useState([]), swiperSlideItems = _c[0], setSwiperSlideItems = _c[1];
    var slideItems = activeFamousDates.historicalEvents.map(function (item) {
        return (_jsx(SwiperSlide, { children: _jsxs(SlideItemContainer, { children: [_jsx(SlideItemTitle, { children: item.year }), _jsx(SlideItemDescription, { children: item.event })] }) }, uuidv4()));
    });
    useEffect(function () {
        if (activeStage !== prevActiveStage) {
            setSwiperSlideItems([]);
            setTimeout(function () { return setSwiperSlideItems(slideItems); }, 900);
        }
        else {
            setSwiperSlideItems(slideItems);
        }
    }, [activeStage, prevActiveStage]);
    return (_jsxs(_Fragment, { children: [Boolean(swiperSlideItems.length) && (_jsx(Title, { children: (_a = activeFamousDates.title) !== null && _a !== void 0 ? _a : ' ' })), _jsx(SwiperWrap, { children: _jsx(Swiper, { modules: [Navigation], navigation: true, breakpoints: {
                        320: {
                            spaceBetween: 25,
                            slidesPerView: 2,
                        },
                        576: {
                            spaceBetween: 30,
                            slidesPerView: 3,
                        },
                        1000: {
                            spaceBetween: 80,
                            slidesPerView: 3,
                        },
                    }, children: swiperSlideItems }) })] }));
};
