import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { ButtonContainer } from '@/components/TimeIntervalPagination/ButtonContainer';
import { Stage } from '@/components/TimeIntervalPagination/Stage';
import { ChangeStageButton } from '@/components/TimeIntervalPagination/ChangeStageButton';
import { FamousDatesContext } from '@/context/FamousDatesContext';
import { Container } from '@/components/TimeIntervalPagination/Container';
export var TimeIntervalPagination = function () {
    var _a = useContext(FamousDatesContext), famousDates = _a.famousDates, activeStage = _a.activeStage, setActiveStage = _a.setActiveStage, setPrevActiveStage = _a.setPrevActiveStage;
    var handleClickPrevStage = function () {
        setPrevActiveStage(activeStage);
        setActiveStage(activeStage - 1);
    };
    var handleClickNextStage = function () {
        setPrevActiveStage(activeStage);
        setActiveStage(activeStage + 1);
    };
    var isPrevStageButtonDisabled = activeStage === 1;
    var isNextStageButtonDisabled = activeStage === famousDates.length;
    var stage = "0".concat(activeStage, "/0").concat(famousDates.length);
    return (_jsxs(Container, { children: [_jsx(Stage, { children: stage }), _jsxs(ButtonContainer, { children: [_jsx(ChangeStageButton, { disabled: isPrevStageButtonDisabled, onClick: handleClickPrevStage, direction: "prev" }), _jsx(ChangeStageButton, { disabled: isNextStageButtonDisabled, onClick: handleClickNextStage, direction: "next" })] })] }));
};
