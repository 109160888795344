export var famousDatesData = [
    {
        title: 'Технологии',
        stage: 1,
        timeInterval: {
            start: 1980,
            end: 1986,
        },
        historicalEvents: [
            {
                year: 1980,
                event: 'Sinclair Research выпускает домашний компьютер ZX80',
            },
            {
                year: 1982,
                event: 'Появился домашний компьютер ZX Spectrum, выпущенный британской компанией Sinclair Research',
            },
            {
                year: 1985,
                event: 'Microsoft выпустила свою первую операционную систему Windows',
            },
            {
                year: 1986,
                event: 'Создание протокола управления сетевыми устройствами SNMP',
            },
        ],
    },
    {
        title: 'Кино',
        stage: 2,
        timeInterval: {
            start: 1987,
            end: 1991,
        },
        historicalEvents: [
            {
                year: 1987,
                event: '«Хищник»/Predator, США (реж. Джон Мактирнан)',
            },
            {
                year: 1988,
                event: '«Кто подставил кролика Роджера»/Who Framed Roger Rabbit, США (реж. Роберт Земекис)',
            },
            {
                year: 1989,
                event: '«Назад в будущее 2»/Back To The Future 2, США (реж. Роберт Земекис)',
            },
            {
                year: 1990,
                event: '«Крепкий орешек 2»/Die Hard 2, США (реж. Ренни Харлин)',
            },
            {
                year: 1991,
                event: '«Семейка Аддамс»/The Addams Family, США (реж. Барри Зонненфельд)',
            },
        ],
    },
    {
        title: 'Литература',
        stage: 3,
        timeInterval: {
            start: 1992,
            end: 1997,
        },
        historicalEvents: [
            {
                year: 1992,
                event: 'Нобелевская премия по литературе — Дерек Уолкотт, «За блестящий образец карибского эпоса в 64 разделах»',
            },
            {
                year: 1994,
                event: 'Бессоница — Стивена Кинга',
            },
            {
                year: 1995,
                event: 'Нобелевская премия по литературе — Шейман Хини',
            },
            {
                year: 1997,
                event: '«Гарри Поттер и философский камень» — Джоан Роулинг',
            },
        ],
    },
    {
        title: null,
        stage: 4,
        timeInterval: {
            start: 1999,
            end: 2004,
        },
        historicalEvents: [
            {
                year: 1999,
                event: 'Премьера балета «Золушка» в постановке Жан-Кристофа Майо, сценография Эрнеста Пиньона',
            },
            {
                year: 2000,
                event: 'Возобновлено издание журнала «Театр»',
            },
            {
                year: 2002,
                event: 'Премьера трилогии Тома Стоппарда «Берег Утопии», Королевский Национальный театр, Лондон',
            },
            {
                year: 2003,
                event: 'Премия «Оскар» за лучший фильм «Чикаго»',
            },
        ],
    },
    {
        title: 'Спорт',
        stage: 5,
        timeInterval: {
            start: 2006,
            end: 2014,
        },
        historicalEvents: [
            {
                year: 2006,
                event: 'Баскетбольный клуб ЦСКА стал победителем национального первенства России',
            },
            {
                year: 2008,
                event: 'С 8 по 24 августа в Пекине прошли 29-е летние Олимпийские игры',
            },
            {
                year: 2010,
                event: '13—28 февраля в Ванкувере: Зимние Олимпийские игры 2010 года',
            },
            {
                year: 2012,
                event: '2 августа — Летние Олимпийские игры',
            },
            {
                year: 2014,
                event: 'XXII зимние Олимпийские игры (Сочи, Россия)',
            },
        ],
    },
    {
        title: 'Наука',
        stage: 6,
        timeInterval: {
            start: 2015,
            end: 2022,
        },
        historicalEvents: [
            {
                year: 2015,
                event: '13 сентября — частное солнечное затмение, видимое в Южной Африке и части Антарктиды',
            },
            {
                year: 2016,
                event: 'Телескоп «Хаббл» обнаружил самую удалённую из всех обнаруженных галактик, получившую обозначение GN-z11',
            },
            {
                year: 2017,
                event: 'Компания Tesla официально представила первый в мире электрический грузовик Tesla Semi',
            },
            {
                year: 2018,
                event: 'Старт космического аппарата Solar Probe Plus, предназначенного для изучения Солнца',
            },
            {
                year: 2019,
                event: 'Google объявил о создании 53-кубитного квантового компьютера',
            },
            {
                year: 2020,
                event: 'Корабль Crew Dragon вернулся на Землю из первого пилотируемого полёта',
            },
        ],
    },
];
