export var calculateNavItemPosition = function (_a) {
    var stage = _a.stage, amountItems = _a.amountItems;
    var angle = (360 / amountItems) * (stage + 4);
    var x = 100 * Math.cos((angle * Math.PI) / 180);
    var y = 100 * Math.sin((angle * Math.PI) / 180);
    return {
        top: 50 + y,
        left: 50 + x,
    };
};
